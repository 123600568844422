.error-page {
    background-color: $streambank-blue80;
    color: $streambank-white;
    height: 100%;
    width: 100%;

    .error-container {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        div {
            padding-bottom: 15px;
        }

        .error-text {
            font-size: 18px;
        }

        .error-message {
            text-align: center;
        }

        .btn-complete {
            margin-right: 10px;
        }
    }
}
