.photo-tool-page {
    background-color: $streambank-blue80;
    color: $streambank-white;
    font-size: 12px;

    &>.col {
        padding-right: 10px;

        .streambank-page {
            padding-top: 0;

            .streambank-body {
                overflow: inherit;
                overflow-y: inherit;
                height: 100%;

                .content {
                    padding: 30px 0;

                    & > .row {
                        height: 100%;

                        .col-6 {
                            padding-left: 8px;
                            padding-right: 8px;
                        }
                    }
                }
            }
        }
    }

    .header {
        .fa-camera {
            margin-right: 0.5rem;
        }
    }

    .photo-container {
        display: flex;
        flex-direction: column;
        align-items:center;
        width: 100%;
        height: 50vh;

        &.reference-photo {
            background-color: $streambank-blue70;
            border-radius: 8px;
        }

        .photo-title {
            padding: 16px 0;

            &:empty:before {
                content: "\200b"; // unicode zero width space character
            }
        }

        &.main {
            .photo > img {
                border: 3px solid $streambank-white;
            }
        }

        .photo {
            width: 100%;
            height: 75%;
            border-radius: 5px;
            overflow: hidden;
            -webkit-transform: translateZ(0);

            .flash-area {
                position: absolute;
                background-color: white;
                width: 100%;
                height: 100%;
                animation: flash 0.5s;
                animation-fill-mode: forwards;
                z-index: 100;

                @keyframes flash {
                    from {
                        opacity: 1;
                    }
                    to {
                        opacity:0;
                    }
                }
            }

            &.no-photo {
                border: 3px solid $streambank-white;

                .no-photo-message {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    color: $streambank-primary;

                    & > svg {
                        font-size: 40px;
                        padding-bottom: 8px;
                    }
                }
            }

            &>img {
                z-index: 1;
            }
            
            &>img, &>video {
                position: absolute;
                width: 100%;
                height: 100%;
                border-radius: 5px;
                object-fit: cover;
            }
            
            &>canvas {
                image-rendering: crisp-edges;
            }

            &>video {
                border: 3px solid $streambank-danger;
                object-fit: cover;
            }

            &.grid-on {
                img, video {
                    position: absolute;
                    top: 0;
                }
                
                img {
                    z-index: -1;
                }
                
                video {
                    z-index: -2;
                }
            }

            .table-grid {
                width: 100%;
                height: 100%;
                border-radius: 5px;
                z-index: 1;

                tr {
                    td {
                        width: 25%;
                        border: 1px solid rgba(255, 255, 255, .2);

                        &:nth-child(2) {
                            border-right: 1px solid $streambank-danger;
                        }

                        &:first-child {
                            border-left: none;
                        }

                        &:last-child {
                            border-right: none;
                        }
                    }

                    &:nth-child(2)>td {
                        border-bottom: 1px solid $streambank-danger;
                    }

                    &:first-child>td {
                        border-top: none;
                    }

                    &:last-child>td {
                        border-bottom: none;
                    }
                }
            }
        }

        .photo-actions {
            padding-top: 8px;

            button {
                margin-right: 16px;
            }
        }
    }

    .photo-tool-sidebar {
        background-color: $streambank-blue70;
        padding-right: 8px;
        padding-left: 8px;

        /* prevent selection on buttons in sidebar */
        -webkit-user-select: none !important;

        .content {
            padding: 30px 0;
            display: flex;
            flex-flow: column;

            .photo-tools {
                flex-grow: 1;

                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 100%;

                .take-photo-icon {
                    font-size: 50px;

                    .fa-circle.fa-inverse {
                        color: $streambank-white;
                    }
                }

                .retake-photo-icon {
                    font-size: 50px;

                    .fa-circle.fa-inverse {
                        color: $streambank-blue80;
                    }
                }

                .tool-group {
                    text-align: center;
                    padding: 30px 0;

                    &.disabled {
                        color: $streambank-blue50;
                    }

                    &>svg {
                        font-size: 24px;
                    }

                    &.active {
                        color: $streambank-warning;
                    }
                }
            }
        }
    }
}
