.login-page {
    width: 100vw;
    height: 100vh;

    .login-modal {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .login-header {
            display: flex;
            flex-direction: column;

            h2 {
                margin-bottom: 0;
            }

            .logo {
                min-height: 20px;
                width: calc(100% - 1em);
                padding-bottom: 2em;
                align-self: center;

                margin: 2em auto;
                @media (orientation: portrait) {
                    margin: 1em auto;
                }

            }
        }

        .container {
            min-width: 20em;
            max-width: 33.3%;
            background-color: $streambank-background-white;
            padding: 30px;
            border-radius: 0.3em;

            .row {
                flex-direction: column;

                &>.col-md-3 {
                    padding-bottom: 10px;
                    max-width: 100%;
                }
            }

            .login-btn, .forgot-password {
                .col-md-9 {
                    display: flex;
                    justify-content: center;
                    button {
                        white-space: nowrap;
                    }
                }

                // Empty columns
                .col-md-3 {
                    display: none;
                }

                .spinner-border {
                    margin-left: 10px;
                }
            }

            .login-form-label {
                color: $streambank-grey10;
                font-size: 12px;
                padding-bottom: 10px;
            }

            .remember-me-form {
                display: none;
            }

            form {
                .form-control, .input-group-append {
                    margin-bottom: 10px;
                }
            }

            .verification-code-field {
                flex-direction: row;

                .col-md-9 {
                    max-width: 60%;
                    flex: 0 0 60%;
                }

                .col-md-3 {
                    max-width: 40%;
                    flex: 0 0 40%;

                    &:first-child {
                        max-width: 100%;
                        flex: 1 1 100%;
                    }

                    .monitoring-resend-btn {
                        border-color: #ccc;
                        background-color: $streambank-background-white;
                        color: $streambank-blue80;
                        margin-left: 10px;

                        &:disabled {
                            color: #ccc;
                        }
                    }
                }
            }

            .form-group[validationstate="error"]>label {
                padding-top: 5px;
                font-size: 12px;
                color: $streambank-danger;
            }

            .login-btn {
                padding-top: 15px;

                .btn-primary {
                    background-color: $streambank-complete;
                    border-color: $streambank-complete;
                    padding: 5px 70px;
                    margin-bottom: 10px;

                    &:focus, &:active, &.active, &:hover, &:active:hover {
                        background-color: darken($streambank-complete, 10);
                    }
                }

                .btn-secondary {
                    padding: 5px 49px;
                }

                .send-btn, .submit-btn {
                    background-color: $streambank-blue60;
                    border-color: $streambank-blue60;
                }

                .cancel-btn {
                    display: flex;
                    justify-content: center;
                    padding-top: 20px;
                }

                .resend-btn {
                    display: none;
                }
            }

            .col-md-6, .col-md-9 {
                max-width: 100%;
                flex: 1;
            }

            // Empty columns
            .col-md-3.col-12{
                display: none;
            }

            .forgot-password {
                padding-top: 10px;
            }
        }
    }
}

.logout-page {
    width: 100vw;
    height: 100vh;
}

.background-image {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: $streambank-blue80;
    background-image: url("/images/background-med.jpg");
    background-size: cover;
    background-position: center;

    > .overlay {
        background: $streambank-blue80;
        opacity: 0.75;
        width: 100%;
        height: 100%;
        position: absolute;
    }
    
    // I have no idea why this is needed
    z-index: -1;
}
