.offline-data-download-page {
    .loading-spinner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 50vh;
    }

    .spinner-border {
        color: $streambank-teal40;
    }

    .offline-data-title {
        display: flex;
        flex-direction: row;
        align-items: center;

        .spinner-border {
            margin-left: 10px;
        }
    }
    
    .sync-unavailable {
        margin-bottom: 1em;
    }
}

.data-page-notice {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    &>div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 10px;
        border: 1px solid $streambank-grey30;
        border-radius: 5px;
        
        >button {
            white-space: nowrap;
        }
    }
}

.data-page-actions {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    
    > div.organization {
        min-width: 12.25em;
    }
    
    > div.photo-quality {
        min-width: 7em;
    }

    & > div {
        display: flex;
        flex-direction: column;
        width: 160px;

        > button {
            white-space: nowrap;
        }
    }

    .form-group {

        &:not(:last-child) {
            margin-right: 10px;
        }

        >.form-label {
            margin-left: 3px;
        }
    }

    .btn-sync {
        padding: 6px 20px;
        margin-bottom: 4px;
    }

    .total-download-size {
        text-align: center;
        font-size: 10px;
        min-height: 1.5em;
        white-space: nowrap;
        
        // This fixes overlapping text on Safari
        background-color: $streambank-grey50;

        span {
            margin: 0 2px;

            svg {
                margin-right: 2px;
            }
        }
    }
}

.project-list {
    .tree-header {
        font-family: "Source Serif Pro", serif;
        font-size: 14px;
        display: flex;
        align-items: center;
        background-color: $streambank-blue70;
        color: $streambank-white;
        padding: 0 20px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        min-height: 50px;
        cursor: pointer;

        &.collapsed {
            border-radius: 5px;
        }

        &>div>svg {
            margin-right: 10px;
        }

        span {
            font-size: 12px;
            vertical-align: middle;
        }

        //.btn-deselect {
        //    background-color: $streambank-darkblue;
        //    border-color: $streambank-darkblue;
        //    margin-left: 10px;
        //}
    }

    .tree-container {
        background-color: $streambank-grey40;
        padding: 15px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        .react-checkbox-tree {
            .rct-text {
                padding: 4px;
                max-width: calc(100% - 100px);

                .rct-collapse {
                    @extend %small-touchable-area;
                }

                .rct-collapse, rct-checkbox {
                    padding: 0 2px 0 0;
                }

                .rct-checkbox {
                    margin-left: 5px;
                }

                .rct-icon {
                    width: 9px;
                }

                & > label {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    background-color: $streambank-grey25;
                    border-radius: 5px;
                    padding: 6px 6px 6px 34px;
                    margin-left: 8px;

                    .rct-title {
                        width: 100%;
                        font-size: 14px;
                        padding: 0 6px 0 2px;

                        .tree-node {
                            font-family: "Source Sans Pro", sans-serif;
                            display: flex;
                            align-items: center;
                            width: 100%;

                            .tree-prefix {
                                position: relative;
                                left: -55px;
                                width: 0;
                                color: $streambank-grey10;

                                .fa-cloud-download {
                                    font-size: 16px;
                                    vertical-align: -2px;
                                }

                                .fa-layers {
                                    vertical-align: -2px;

                                    &.success {
                                        color: $streambank-green20;
                                    }

                                    &.danger {
                                        color: $streambank-danger;

                                        &.inverse {
                                            color: $streambank-white;

                                            .fa-arrow-down {
                                                color: $streambank-danger;
                                            }
                                        }
                                    }
                                }
                            }

                            .tree-node-content {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                font-size: 12px;
                                width: 100%;

                                svg {
                                    font-size: 14px;
                                    color: $streambank-blue50;
                                    margin-right: 4px;

                                    &.fa-clipboard-list {
                                        margin-left: 2px;
                                    }
                                }

                                .tree-status {
                                    font-size: 10px;

                                    .label-danger {
                                        color: $streambank-danger;
                                    }

                                    .btn-event {
                                        border-radius: 15px;
                                        padding: 3px 10px;
                                        margin-left: 8px;
                                        font-weight: 300;
                                        pointer-events: initial;

                                        .fa-plus {
                                            color: $streambank-white;
                                            font-size: 11px;
                                            margin-left: 0;
                                        }
                                    }
                                }
                            }

                            .tree-suffix {
                                position: relative;
                                right: -20px;
                                width: 0;
                                white-space: nowrap;
                                pointer-events: none;

                                .tree-suffix-container {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    width: 90px;

                                    .tree-node-file-size {
                                        font-size: 10px;
                                    }

                                    .tree-node-action {
                                        margin-left: 15px;

                                        button {
                                            pointer-events: initial;

                                            .node-remove-icon {
                                                .fa-circle {
                                                    color: $streambank-danger;
                                                }
                                            }

                                            &:disabled {
                                                pointer-events: none;

                                                .fa-circle {
                                                    color: $streambank-grey25;
                                                }

                                                .fa-trash-alt {
                                                    color: $streambank-grey10;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .rct-node {
                & > .rct-text {
                    padding-left: 10px;
                }

                & .rct-node {
                    & > .rct-text {
                        padding-left: 19px;
                    }

                    & .rct-node {
                        & > .rct-text {
                            padding-left: 29px;
                        }

                        & .rct-node {
                            & > .rct-text {
                                padding-left: 38px;
                            }
                        }
                    }
                }
            }

            .fa-check-square, .fa-square, .fa-circle, .fa-layers {
                font-size: 16px;
                vertical-align: -1px;
            }

            .fa-check-square, .fa-layers>.fa-square, .fa-layers>svg:first-child.fa-circle {
                color: $streambank-primary;
            }

            .fa-square, .fa-circle {
                color: $streambank-white;
            }

            .synced-node li:first-child .rct-checkbox,
            li.rct-node-parent.synced-node > .rct-text > label > .rct-checkbox {
                .fa-check-square, .fa-layers>.fa-square {
                    color: $streambank-success;
                }
            }

            .disabled-node {
                & > .rct-text > label {
                    pointer-events: none;

                    & > .rct-checkbox {
                        .fa-check-square {
                            color: $streambank-grey23;
                        }

                        .fa-square {
                            color: $streambank-grey23;
                        }

                        .fa-circle {
                            color: $streambank-grey23;
                        }
                    }
                }

                //.fa-layers {
                //    &>svg {
                //        color: $background-text-gray;
                //
                //        &:first-child {
                //            color: $streambank-grey20;
                //        }
                //    }
                //}
            }

            .btn-link {
                padding: 0;

                &:hover {
                    background-color: transparent;
                }
            }
        }

        &.hide {
            display: none;
        }
    }
}
