@import "./variables.scss";

%small-touchable-area {
    position: relative;

    &:after {
        content: '';
        position: absolute;
        top: -10px;
        bottom: -10px;
        left: -10px;
        right: -10px;
        //border: 1px solid red; // uncomment this to debug the touchable area
    }
}

nav.navbar {
    background-color: $streambank-blue80;

    img {
        padding-left: 15px;

        &:hover {
            cursor: pointer;
        }
    }
}

#root, .streambank-layout, .streambank-page {
    height: 100%;
}

body {
     font-family: "Source Sans Pro", sans-serif;
     font-size: 12px;
     background-color: $streambank-background-white;
     color: $streambank-blue80;
     height: 100vh;
 }

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
    font-family: "Source Serif Pro", serif;
    font-size: 18px;
}

h2 {
    font-family: "Source Serif Pro", serif;
    font-size: 1.2rem;
    font-weight: 400;
}

hr {
    width: 100%;
}

.streambank-layout.return-bar-visible {
    .streambank-page {
        padding-top: 95px;
    }

    .streambank-sidebar {
        padding-top: 40px;

        .btn-collapse {
            top: 130px;
        }
    }
}

input.form-control, select.custom-select {
    font-size: 12px;
    color: $streambank-blue80;
}

a:hover {
    text-decoration: none;
}

a:not([href]):not([class]) {
    color: $streambank-blue70;
    font-weight: 400;

    &:hover {
        cursor: pointer;
    }
}

.btn {
    font-size: 12px;

    >svg {
        margin-right: 0.5rem;
    }

    &.btn-primary {
        background-color: $streambank-primary;
        border-color: $streambank-primary;
        color: $streambank-white;

        &:focus, &:active, &.active, &:hover, &:active:hover {
            background-color: darken($streambank-primary, 10);
        }

        &[disabled] {
            border-color: $streambank-grey30;
            background-color: $streambank-grey30;
            color: $streambank-grey10;
        }
    }

    &.btn-success {
        background-color: $streambank-success;
        border-color: $streambank-success;
        color: $streambank-white;

        &:focus, &:active, &.active, &:hover, &:active:hover {
            background-color: darken($streambank-success, 10);
        }

        &[disabled] {
            border-color: $streambank-grey30;
            background-color: $streambank-grey30;
            color: $streambank-grey10;
        }
    }

    &.btn-secondary {
        background-color: $streambank-blue60;
        border-color: $streambank-blue60;
        color: $streambank-white;

        &:focus, &:active, &.active, &:hover, &:active:hover {
            background-color: darken($streambank-blue60, 10);
        }

        &[disabled] {
            border-color: $streambank-grey30;
            background-color: $streambank-grey30;
            color: $streambank-grey10;
        }
    }

    &.btn-danger {
        background-color: $streambank-danger;
        border-color: $streambank-danger;
        color: $streambank-white;

        &:focus, &:active, &.active, &:hover, &:active:hover {
            background-color: darken($streambank-danger, 10);
        }

        &[disabled] {
            border-color: $streambank-grey30;
            background-color: $streambank-grey30;
            color: $streambank-grey10;
        }
    }

    &.btn-complete {
        background-color: $streambank-complete;
        border-color: $streambank-complete;
        color: $streambank-white;

        &:focus, &:active, &.active, &:hover, &:active:hover {
            background-color: darken($streambank-complete, 10);
        }

        &[disabled] {
            border-color: $streambank-grey30;
            background-color: $streambank-grey30;
            color: $streambank-grey10;
        }
    }

    &.btn-link {
        color: $streambank-blue80;

        &:focus, &:active, &.active, &:hover, &:active:hover {
            color: darken($streambank-blue80, 10);
        }
    }

    &.btn-action {
        background-color: $streambank-blue60;
        border-color: $streambank-blue60;
        color: $streambank-white;

        &:focus, &:active, &.active, &:hover, &:active:hover {
            background-color: darken($streambank-blue60, 10);
        }

        &[disabled] {
            border-color: $streambank-grey30;
            background-color: $streambank-grey30;
            color: $streambank-grey10;
        }
    }

    &.btn-outline-secondary {
        color: $streambank-grey10;
        border-color: $streambank-grey20;

        &:focus, &:active, &.active, &:hover, &:active:hover {
            background-color: $streambank-grey20;
            color: $streambank-white;
        }
    }
}

.btn-return {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 12px;
    padding: 2px 15px;
    border-radius: 15px;

    .fa-external-link {
        margin-left: 10px;
        margin-right: 0;
        font-size: 10px;
    }
}

.return-link {
    color: $streambank-primary;
    cursor: pointer;

    .fa-external-link {
        margin-left: 5px;
        margin-right: 0;
        font-size: 10px;
    }
}

.font-weight-bold {
    font-weight: 600 !important;
}

.invalid-feedback {
    display: block;
    color: $streambank-danger;
}

svg.info-popover {
    margin-left: 6px;
}

.content {
    padding-top: 55px;
    padding-bottom: 20px;
    height: 100%;
}

.project-name-short {
    display: flex;

    > div:first-child {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.streambank-page {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 55px;

    .streambank-header {
        padding-bottom: 10px;
    }

    .streambank-body {
        overflow: hidden;
        overflow-y: scroll;
        padding-bottom: 20px;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .page-footer {
        margin-top: auto;
        padding-top: 10px;
        padding-bottom: 20px;
    }
}

.form-group {
    label {
        font-size: 12px;
    }

    input, select, textarea {
        font-size: 12px;
        border-radius: 5px;
        resize: none;

        &::placeholder {
            color: $streambank-grey20;
        }

        &.sb-finalized[readonly] {
            background-color: $streambank-white;
            &:focus {
                box-shadow: none;
                border-color: #ced4da; //$input-border-color;
            }
        }
    }

    select {
        &.sb-finalized[disabled] {
            opacity: 1;
            color: $streambank-blue80;
            background-color: $streambank-white;
        }
    }

    .input-group {
        &.has-prefix input {
            border-left: none;
        }

        &.has-suffix input {
            border-right: none;
        }

        .input-group-text {
            font-size: 12px;
            background-color: $streambank-white;
            color: $streambank-grey20;
        }
    }

    &.required {
        label {
            &::after {
                content: "*";
                color: $streambank-danger;
                padding-left: 5px;
                font-weight: 700;
            }
        }
    }

    &.danger {
        .input-group {
            border-radius: 5px;
            border: 1px solid $streambank-danger;
        }
    }

    &.checkbox-list {
        overflow-y: scroll;

        .input-group {
            margin-bottom: 10px;

            input[readonly] {
                background-color: $streambank-white;
                border-left: none;
                cursor: auto;
            }
        }
    }
}

.form-check-input {
    margin-top: 0.1rem;
}

.tooltip {
    .arrow::before {
        border-bottom-color: $streambank-blue30;
    }

    .tooltip-inner {
        background-color: $streambank-blue30;
        font-size: 12px;
        padding: 10px;
    }
}

.page-title-component {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 24px;
    margin-top: 20px;

    .page-title {
        margin-bottom: 0;

        &.sm {
            font-size: 1.2rem;
            font-weight: 300;
        }

        .read-only-icon {
            color: $streambank-danger;
            cursor: pointer;
            margin-left: 8px;
            font-size: 70%;
        }
    }

    .page-subtitle {
        font-weight:300;
    }
}

.streambank-modal {
    .modal-content {
        background-color: $streambank-background-white;

        .container {
            padding-left: 25px;
            padding-right: 25px;
        }

        .modal-header, .modal-body, .modal-footer {
            padding-left: 0;
            padding-right: 0;
        }

        .modal-header {
            .modal-title {
                font-family: "Source Serif Pro", serif;
                font-size: 18px;
            }
        }

        .modal-body {
            max-height: 75vh;
            overflow: hidden;
            overflow-y: auto;

            ol {
                list-style: disc;
                padding-left: 1rem;
                margin-bottom: initial;
            }

            li {
                margin-left: 1em;
            }

            .node-list {
                margin-top: 1.5rem;
                max-height: calc(100vh - 250px);
            }

            .sync-errors {
                padding-left: 40px;
                padding-right: 40px;
                max-height: calc(100vh - 250px);
            }

            iframe {
                width: 100%;
                height: 70vh;
                border: none;
            }
        }

        .modal-footer {
            border-top: none;
            justify-content: center;
        }
    }
}

.tile-list {
    margin-left: -8px;
    margin-right: -8px;

    .tile-item {
        font-family: "Source Serif Pro", serif;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 75px;
        height: 100%;
        background-color: $streambank-grey30;
        color: $streambank-grey10;
        padding: 5px 16px;
        border-radius: 8px;
        cursor: pointer;

        &.primary {
            background-color: $streambank-primary;
            color: $streambank-white;
        }

        &.success {
            background-color: $streambank-complete;
            color: $streambank-white;
        }

        .tile-main {
            display: flex;
            flex-direction: row;
            flex-grow: 1;
            place-items: center;
            justify-content: space-between;

            .tile-content {
                flex-direction: column;
                flex-grow: 1;
                justify-content: center;
                overflow: hidden;

                .tile-title {
                    text-align: center;
                    font-size: 16px;

                    .fa-check-circle {
                        position: unset;
                        margin-right: 8px;
                        font-size: 1rem;
                    }
                }

                .tile-subtitle {
                    font-size: 14px;
                }

            }

            > svg {
                font-size: 1.3rem;

                &:first-child {
                    margin-right: 0.5rem;
                }

                &:last-child {
                    margin-left: 0.5rem;
                }

                &.fa-angle-right {
                    font-size: 1.7em;
                }

                &.fa-clipboard-list {
                    margin-left: 3px;
                }
            }

            .tile-actions {
                display: flex;
                font-size: 1.3rem;
                justify-content: space-evenly;
                margin-top: 1rem;

                > a, > span {
                    margin: 0.5rem;
                }

                svg {
                    &.fa-circle {
                        color: $streambank-blue70;
                    }
                }

                .fa-layers {
                    &.success {
                        svg.fa-circle {
                            color: $streambank-success;
                        }

                        .fa-check-circle {
                            color: $streambank-blue70;
                        }
                    }
                    &.danger {
                        svg.fa-circle {
                            color: $streambank-danger;
                        }
                    }
                }

                & > a {
                    line-height: 1em;
                }
            }
        }

        &.ellipsis .tile-title, &.ellipsis .tile-subtitle {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .tile-progress {
            .progress {
                background-color: $streambank-blue45;
                height: 4px;

                .progress-bar {
                    background-color: $streambank-green5;
                }
            }
        }
    }

    .tile-details {
        display: flex;
        align-items: center;
        background-color: $streambank-grey40;
        margin: 6px 8px 6px -8px;
        border-radius: 0 6px 6px 0;
        overflow: hidden;

        .form-group {
            margin-bottom: 5px;

            .form-label {
                color: $streambank-grey10;
                margin-bottom: 0;
                font-size: 11px;
            }

            & > div {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

.tile-list-dashboard, .tile-list-quant, .tile-list-qual, .tile-list-project-details {
    // i have no idea why these need this
    // but .tile-list-project-details is the only one with details
    .tile-list-column {
        padding: 6px 8px;
        z-index: 1; // Higher than tile-details
    }
}

.tile-list-dashboard, .tile-list-quant, .tile-list-qual {
    .tile-list-column {
        .tile-item .tile-main .tile-content .tile-title {
            text-align: left;
        }
    }
}

.filter.dropdown {
    .dropdown-toggle {
        border-radius: 25px;
        padding: 4px 16px;

        &::after {
            margin-left: 0.5rem;
            vertical-align: 1px;
        }
    }

    .dropdown-item {
        font-size: 14px;
    }
}

.footer-nav {
    display: flex;

    .footer-item {
        &:first-child {
            display:flex;
            flex: 1;
        }

        &:last-child {
            display:flex;
            flex: 1;
            justify-content: flex-end
        }

        a > button {
            min-width: 11em;

            &.rust {
                background-color: $streambank-brown40;
                border-color: $streambank-brown40;
            }
        }
    }

    svg {
        &.fa-angle-right {
            margin-left: 0.5rem;
            margin-right: 0;
        }
    }
}

.sync-dialog {
    .col-12 {
        display: flex;
        justify-content: center;

        .btn-success {
            padding: 8px 30px;
            margin-bottom: 8px;
        }
    }

    .modal-content {
        padding: 0;

        .container {
            padding: 0;
        }

        .modal-header {
            margin-left: 25px;
            margin-right: 25px;

            .fa-check-circle {
                margin-right: 8px;
                font-size: 16px;
                color: $streambank-green20;
            }

            .spinner-border {
                margin-right: 8px;
                color: $streambank-teal40;
                vertical-align: middle;
            }
        }

        .modal-body {
            .col-12 {
                flex-direction: column;

                &.progress-container {
                    .progress-text {
                        align-self: center;
                        padding-top: 10px;
                        margin-left: 25px;
                        margin-right: 25px;
                        z-index: 1;
                    }

                    .progress {
                        background-color: $streambank-grey20;
                        height: 8px;
                        margin-left: 25px;
                        margin-right: 25px;
                        z-index: 1;

                        .progress-bar {
                            background-color: $streambank-green20;
                            transition: width 3s ease-out;
                        }
                    }

                    &.step-progress {
                        background-color: $streambank-grey40;
                        padding-top: 25px;
                        padding-bottom: 20px;
                        margin-bottom: 40px;

                        .progress-bar {
                            background-color: $streambank-primary;
                        }
                    }
                }
            }
        }
    }
}

.circled-icon {
    .spinner-border-sm {
        width: 0.8rem;
        height: 0.8rem;
        position: relative;
        top: -3px;
        color: $streambank-white;
    }

    &.disabled {
        svg.fa-circle {
            color: $streambank-grey30;
        }
    }
}

.photo-point-row {
    width: 100%;
    padding-top: 30px;

    .photo-point-title {
        font-family: "Source Serif Pro", serif;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        padding-bottom: 15px;

        svg.fa-circle {
            color: $streambank-primary;
            cursor: pointer;
        }

        span {
            margin-right: 8px;
        }
    }

    .photo-point-content {
        .photo {
            width: 100%;
            position: relative;

            &::after {
                display: block;
                content: '';
                padding-bottom: 56.25%;
            }
        }
    }
}

.photo-point-row .photo-point-content .photo,
.review-and-finalize-page .streambank-table .photo {
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
    }

    .no-image {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        background-color: $streambank-grey30;
        color: $streambank-grey10;
        width: 100%;
        height: 100%;
        border-radius: 8px;

        .fa-file-image {
            font-size: 30px;
            margin-bottom: 10px;
        }

        .fa-camera {
            font-size: 40px;
            color: $streambank-blue60;
            margin-bottom: 20px;
        }

        .btn-action {
            padding: 4px 36px;
        }
    }
}

.repeatable-section {
    .repeatable-item {
        border: 1px solid $streambank-grey25;
        border-radius: 5px;
        padding: 10px;
        margin-bottom: 10px;
    }

    .fa-layers {
        @extend %small-touchable-area;
        position: absolute;
        right: 18px;
        margin-top: -15px;

        .fa-circle {
            color: $streambank-danger;
        }
    }

    .photo {
        .fa-layers {
            right: -6px;
            margin-top: -5px;
            z-index: 1;
        }
    }
}

.streambank-table {
    border-spacing: 1px 1px;
    border-collapse: separate;
    width: 100%;

    td {
        background-color: $streambank-white;
        border-radius: 8px;
        height: 1.5em;
        line-height: 1.5em;
        vertical-align: middle;

        &:first-child {
            color: $streambank-blue70;
        }

        .table-responsive {
            tr {
                &:nth-child(even) {
                    td {
                        background-color: $streambank-grey40;
                    }
                }

                &:nth-child(odd) {
                    td {
                        background-color: $streambank-white;
                    }
                }
            }
        }
    }

    tr {
        &:nth-child(even) {
            td {
                background-color: $streambank-white;
            }
        }

        &:nth-child(odd) {
            td {
                background-color: $streambank-grey40;
            }
        }
    }

    &.half-table {
        > tbody > tr > td {
            width: 50%;
        }
    }

    td.transect-name, th.transect-name {
        width: 33.33%;
    }
}

.complete-checkbox {
    display: inline-block;
    background-color: $streambank-complete;
    color: $streambank-white;
    padding: 7px;
    border-radius: 0.25rem;
    margin-right: 12px;
}

.button-overlay-hack {
    position: relative;
    display: inline-block;

    & > .hidden-div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
    }
}

// fix for SB-2115 date "pm" wrapping to the next line 
input[type=date].form-control, input[type=datetime-local].form-control, input[type=month].form-control, input[type=time].form-control {
    white-space: nowrap;
}
