$streambank-white: #ffffff;
$streambank-black: #000000;

$streambank-green5: #8FFF74;
$streambank-green10: #89f571;
$streambank-green20: #6cc359;
$streambank-green30: #B0D399;
$streambank-green40: #789862;
$streambank-green50: #648051;
$streambank-green60: #506740;
$streambank-green65: #526942;
$streambank-green70: #425535;

$streambank-teal10: #bce3df;
$streambank-teal20: #94ded7;
$streambank-teal30: #7ed3cb;
$streambank-teal40: #6cc7be;

$streambank-brown10: #c18868;
$streambank-brown20: #b76e45;
$streambank-brown25: #A0572F;
$streambank-brown30: #aa5628;
$streambank-brown40: #944b23;
$streambank-brown50: #72594f;
$streambank-brown60: #5c473f;
$streambank-brown70: #43332d;
$streambank-brown80: #291e1b;
$streambank-brown90: #6c6941;

$streambank-greyblue10: #acc5d5;
$streambank-greyblue20: #c3d4de;
$streambank-greyblue30: #d5dfe5;

$streambank-red: #ea585e;
$streambank-yellow: #fff57b;

$streambank-grey10: #7b828c;
$streambank-grey20: #b3b3b3;
$streambank-grey23: #c6c6c6;
$streambank-grey25: #d2d2d2;
$streambank-grey30: #d9d9d9;
$streambank-grey40: #e5e5e5;
$streambank-grey50: #f2f2f2;

$streambank-blue10: #7bc5e0;
$streambank-blue20: #6fb3cc;
$streambank-blue30: #65a6be;
$streambank-blue40: #5791a7;
$streambank-blue45: #264D6C;
$streambank-blue46: #1F394F;
$streambank-blue50: #4f678b;
$streambank-blue60: #415574;
$streambank-blue70: #33425a;
$streambank-blue80: #232e3f;

$streambank-primary: $streambank-blue40;
$streambank-success: $streambank-green10;
$streambank-danger: $streambank-red;
$streambank-warning: $streambank-yellow;
$streambank-complete: $streambank-teal40;
$streambank-background-white: $streambank-grey50;

$streambank-transition: $streambank-blue40;
$streambank-upland: $streambank-brown40;
$streambank-overbank: $streambank-teal40;
$streambank-bank: $streambank-green50;
$streambank-toe: $streambank-grey10;
