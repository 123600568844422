.dashboard-page {
    padding-right: 0;

    .streambank-header {
        padding: unset;
        > hr {
            margin-bottom: unset;
        }
    }

    .program-title {
        

        h1 {
            font-size: 16px;
        }
    }

    .fa-layers {
        margin-right: 10px;
        .fa-circle {
            color: $streambank-primary;
        }
    }
}

.dashboard-page, .qual-veg-tool-page {

    .no-events-message {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px solid $streambank-grey30;
        border-radius: 5px;

        span {
            margin-top: 40px;
            margin-bottom: 15px;
            text-align: center;
        }

        button {
            padding: 6px 60px;
            margin-bottom: 40px;
        }
    }
}