.camera-points-page {
    .tile-list {
        & > .col-3 {
            padding-left: 8px;
            margin-top: 10px;
        }

        .tile-item {
            .tile-title {
                padding-right: 0;
            }
            .tile-progress {
                .progress {
                    margin-bottom: 4px;
                }
            }
        }

        .tile-details {
            margin: 10px 8px 0 -8px;
            padding: 5px 10px;

            .camera-point-details {
                width: 100%;
                padding: 8px 15px;

                .col-6 {
                    &:last-child {
                        border-left: 1px solid $streambank-grey20;
                    }
                    
                    .camera-point-notes {
                        white-space: normal;
                        max-height: 10ex;
                    }
                }
            }
        }
    }
}

.new-camera-point-page {
    .btn-location {
        @extend %small-touchable-area;
        position: absolute;
        top: 37px;
        right: -25px;

        .fa-circle {
            color: $streambank-primary;
        }
    }
}
