.species-table {
    border-spacing: 1px 1px;
    border-collapse: separate;
    width: max-content;

    &.type-invasive-cover {
        td {
            width: 12em;
        }
    }

    td, th {
        &:first-child {
            position: sticky;
            left: 0;
        }
    }

    th {
        text-transform: uppercase;
        font-size: 10px;
        font-weight: 600;
        padding: 6px 12px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        &.table-title {
            font-family: "Source Serif Pro", serif;
            padding-left: 0;
            text-transform: initial;
            font-size: 14px;
            font-weight: 400;
        }

        &.type-blank {
            background-color: $streambank-grey50;
            border-top-left-radius: 0;

            .rounded-cell-container {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                background-color: $streambank-grey30;
            }
        }

        &.type-transition {
            background-color: rgba($streambank-transition, 0.25);
        }

        &.type-upland {
            background-color: rgba($streambank-upland, 0.25);
        }

        &.type-overbank {
            background-color: rgba($streambank-overbank, 0.25);
        }

        &.type-bank {
            background-color: rgba($streambank-bank, 0.25);
        }

        &.type-toe {
            background-color: rgba($streambank-toe, 0.25);
        }
    }

    tr {
        &.sub-header {
            border: none;
            color: $streambank-grey10;
            font-size: 10px;

            & > td:first-child {
                background-color: $streambank-grey50;

                &.fixed-column {
                    padding-left: 10px;

                    span:first-child {
                        margin-right: 20px;
                    }
                }
            }
        }

        td {
            width: 6em;
            vertical-align: middle;

            &.left-border {
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
            }

            &.right-border {
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
            }

            &.species-cell {
                background-color: $streambank-grey50;
                width: 20em;
                z-index: 2;
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;

                .rounded-cell-container {
                    background-color: $streambank-white;

                    .plot-type-number {
                        margin-right: 40px;

                        &:before {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 45px;
                            height: 100%;
                            border-right: 1px solid $streambank-grey50;
                        }
                    }
                }
            }

            &.type-transition {
                color: $streambank-white;
                background-color: $streambank-transition;
            }

            &.type-upland {
                color: $streambank-white;
                background-color: $streambank-upland;
            }

            &.type-overbank {
                color: $streambank-white;
                background-color: $streambank-overbank;
            }

            &.type-bank {
                color: $streambank-white;
                background-color: $streambank-bank;
            }

            &.type-toe {
                color: $streambank-white;
                background-color: $streambank-toe;
            }

            &.type-normal {
                color: $streambank-blue80;
                background-color: $streambank-white;
            }
        }

        &:nth-child(odd) {
            .species-cell {
                .rounded-cell-container {
                    background-color: darken($streambank-white, 2);
                }
            }

            .type-transition {
                background-color: darken($streambank-transition, 10);
            }

            .type-upland {
                background-color: darken($streambank-upland, 10);
            }

            .type-overbank {
                background-color: darken($streambank-overbank, 10);
            }

            .type-bank {
                background-color: darken($streambank-bank, 10);
            }

            .type-toe {
                background-color: darken($streambank-toe, 10);
            }

            .type-normal {
                background-color: darken($streambank-white, 2);
            }
        }
    }

    .rounded-cell-container {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        padding: 10px;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.plot-review-page {
    .streambank-body {
        overflow-x: auto;

        .return-link {
            color: $streambank-blue80;
        }
    }
}
