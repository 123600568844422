.invasive-cover-page, .hydrozone-species-page {
    .page-subtitle {
        .subtitle-pill {
            color: $streambank-white;
            font-size: 15px;
            padding: 4px 15px;
            margin-left: 5px;
            border-radius: 25px;

            &.type-transition {
                background-color: $streambank-transition;
            }

            &.type-upland {
                background-color: $streambank-upland;
            }

            &.type-overbank {
                background-color: $streambank-overbank;
            }

            &.type-bank {
                background-color: $streambank-bank;
            }

            &.type-toe {
                background-color: $streambank-toe;
            }
        }

        .subtitle-desc {
            font-family: "Source Serif Pro", serif;
            font-size: 15px;
            font-style: italic;
            margin-left: 8px;

        }
    }

    .no-plants-field {
        background-color: $streambank-grey40;
        padding: 8px 15px;
        border-radius: 5px;
    }
}

.keyboard-component {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    margin: -15px -30px 0;
    height: 85px;

    .fa-layers {
        @extend %small-touchable-area;
        margin: 20px;
        cursor: pointer;

        // prevent overflow on narrow screens
        max-width: calc(6.5vw - 40px);

        .fa-layers-text {
            color: $streambank-white;
        }
    }

    &.disabled {
        .fa-layers {
            opacity: 80%;
        }
    }

    &.type-stem-tally, &.type-invasive-cover {
        background-color: $streambank-blue70;

        .fa-layers {
            margin: 20px;

            .fa-circle {
                color: $streambank-blue40;
            }

            &.action {
                & > .fa-circle {
                    color: $streambank-blue10;
                }

                & > .fa-layers-text {
                    color: $streambank-blue80;
                }
            }

            &.danger {
                & > .fa-circle {
                    color: $streambank-danger;
                }
            }
        }
    }

    &.type-length-of-cover {
        background-color: $streambank-green65;

        .fa-layers {
            margin: 20px;

            .fa-circle {
                color: $streambank-green40;
            }

            &.action {
                & > .fa-circle {
                    color: $streambank-green30;
                }

                & > .fa-layers-text {
                    color: $streambank-green70;
                }
            }

            &.danger {
                & > .fa-circle {
                    color: $streambank-danger;
                }
            }
        }
    }

    &.type-dbh {
        background-color: $streambank-brown25;

        .fa-layers {
            margin: 20px;

            .fa-circle {
                color: $streambank-brown20;
            }

            &.action {
                & > .fa-circle {
                    color: $streambank-brown10;
                }
            }

            &.danger {
                & > .fa-circle {
                    color: $streambank-danger;
                }
            }
        }
    }
}

.hydrozone-species-list {
    .species-labels {
        font-size: 12px;
        margin-bottom: 8px;
    }

    input {
        caret-color: transparent;
    }

    .input-group-text {
        padding: 9px 0 9px 12px;
    }

    &.quadrat-field {
        input {
            &[disabled] {
                background-color: $streambank-grey40;
            }
        }
    }

    &.type-invasive-cover {
        input, .input-group-text {
            background-color: $streambank-white;
            color: $streambank-blue80;
        }

        .col-6 {
            margin-right: 10px;
        }
    }

    &.type-stem-tally {
        input, .input-group-text {
            background-color: $streambank-blue70;
            color: $streambank-white;

            &:focus {
                background-color: $streambank-blue10;
                color: $streambank-blue80;
            }

            &.sb-finalized[readonly] {
                background-color: $streambank-blue70;
            }
        }
            
        // SB-2128 make trees bold
        div.species-type-id-1 > div.input-group > input[type=text] {
            font-weight: 600;
        }
    }

    &.type-length-of-cover {
        input, .input-group-text {
            background-color: $streambank-green60;
            color: $streambank-white;

            &:focus {
                background-color: $streambank-green30;
                color: $streambank-blue80;
            }

            &.sb-finalized[readonly] {
                background-color: $streambank-green60;
            }
        }
    }

    &.type-dbh {
        input, .input-group-text {
            background-color: $streambank-brown40;
            color: $streambank-white;

            &:focus {
                background-color: $streambank-brown10;
                color: $streambank-blue80;
            }

            &.sb-finalized[readonly] {
                background-color: $streambank-brown40;
            }
        }
    }
    
    .invalid-feedback {
        white-space: nowrap;
    } 
    
}

.invasive-cover-page {
    .btn-random, .btn-notes {
        position: absolute;
        right: -10px;

        .fa-circle {
            color: $streambank-primary;
        }
    }

    .btn-random {
        top: 36px;
        z-index: 1;
    }

    .btn-notes {
        top: 10px;
    }

    .validation-errors {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 10px;
        color: $streambank-danger;
    }

    .quadrat-field > input {
        background-color: $streambank-white;
        color: $streambank-blue80;

        &[disabled] {
            background-color: $streambank-grey40;
        }
    }
}

.species-picker {
    .row {
        margin-left: -25px;
        margin-right: -25px;

        .col-12 {
            padding-left: 25px;
            padding-right: 25px;
        }
    }

    .species-list {
        background-color: $streambank-grey40;
        height: 300px;
        overflow-y: scroll;

        .species-item {
            display: flex;
            justify-content: space-between;
            background-color: $streambank-blue50;
            color: $streambank-white;
            padding: 6px 12px;
            border-radius: 5px;
            margin: 10px 0;
            cursor: pointer;

            .species-icon {
                margin-right: 8px;
            }
        }

        .no-species-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 20px 100px;
            text-align: center;

            .duplicate-warning {
                color: $streambank-danger;
                padding-bottom: 10px;
            }

            .btn {
                width: fit-content;
            }
        }
    }
}
