nav.navbar {
    height: 40px;
    background-color: $streambank-blue80;

    &.return-bar {
        margin-top: 40px;
        background-color: $streambank-primary;
        display: flex;
        justify-content: space-between;
        padding-left: 40px;
        padding-right: 30px;

        .btn {
            padding: 2px 15px;
            border-radius: 15px;

            .fa-redo {
                margin-right: 0;
                margin-left: 10px;
            }
        }

        .fa-times {
            @extend %small-touchable-area;

            color: $streambank-white;
            font-size: 14px;
        }
    }

    .left-nav-bar {
        white-space: nowrap;
        overflow: hidden;
        display: flex;
        margin-right: 15px;
        flex-grow: 1;
    }

    .header-logo {
        display: inline-flex;
        align-items: center;

        .fa-circle {
            width: 10px;
            color: $streambank-blue50;

            &.success {
                color: $streambank-success;
            }

            &.warning {
                color: $streambank-warning;
            }
        }

        img {
            width: 36px;
            padding-left: 15px;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .context-tools {
        padding-right: 15px;

        //.fa-layers {
        //    margin-left: 25px;
        //
        //    &>.fa-circle {
        //        color: $streambank-blue;
        //    }
        //}

    }

    .menu-icons {
        display: flex;
        padding-right: 15px;

        .menu-icon {
            @extend %small-touchable-area;
            cursor: pointer;
            margin-left: 20px;

            svg {
                color: $streambank-teal40;

                &.active {
                    color: $streambank-white;
                }
            }

            .fa-layers.danger {
                position: absolute;
                top: -3px;
                left: 6px;

                svg.fa-circle  {
                    color: $streambank-danger;
                }

                .fa-layers-text {
                    color: $streambank-white;
                    font-size: 10px;
                }
            }
        }
    }

    .header-breadcrumbs {
        display: inline-flex;
        flex-grow: 1;
        padding-left: 20px;
        justify-content: left;
        align-items: center;
        min-width: 0;

        .breadcrumb-container:last-child {
            flex-shrink: 0;
        }

        .breadcrumb-container {
            min-width: 0;
            max-width: 28%;
            display: inline-flex;
            align-items: center;
            cursor: pointer;

            .event-icon {
                margin-right: 0.4rem;
            }

            a {
                color: $streambank-white;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            &.last-breadcrumb {
                background-color: $streambank-blue50;
                border-radius: 15px;
                padding: 3px 15px;
            }

            .fa-angle-right {
                color: $streambank-blue50;
                margin-left: 8px;
                margin-right: 8px;
            }
        }
    }

    .context-tabs {
        display: inline-flex;
        padding-left: 20px;
        justify-content: center;
        align-items: center;

        .context-tab {
            padding: 3px 15px;
            white-space: nowrap;

            &.active {
                background-color: $streambank-primary;
                border-radius: 15px;
            }

            a {
                color: $streambank-white;
            }
        }
    }
}
