.streambank-sidebar {
    .content {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        padding-top: 60px;

        .sidebar-content {
            height: 100%;
            overflow-y: auto;

            .form-group {
                .form-label {
                    font-size: 12px;
                    color: $streambank-grey10;
                    margin-bottom: 4px;
                }

                &>div {
                    font-weight: 400;
                }
            }
        }

        .sidebar-footer {
            margin-top: auto;
            padding-top: 20px;

            .btn {
                width: 100%;
            }
        }
    }

    &.collapsible-sidebar {
        height: 100vh;
        padding-left: 25px;
        padding-right: 25px;
        background-color: $streambank-grey40;

        .btn-collapse {
            position: absolute;
            top: 90px;
            left: -9px;
            cursor: pointer;

            svg.fa-circle {
                color: $streambank-white;
                font-size: 16px;
            }

            svg.fa-angle-right {
                color: $streambank-grey10;
                font-size: 14px;
            }
        }

        .content {
            h1.page-title {
                font-size: 1rem;
            }
        }

        &.show {
            max-width: 25%;
            flex: 0 0 25%;
            padding-left: 25px;
            padding-right: 25px;
            transition: all 500ms;

            .content {
                opacity: 100;
                transition-delay: 420ms;
                transition-duration: 500ms;
            }

            .btn-collapse {
                svg.fa-angle-right {
                    transform: scaleX(1);
                    transition-duration: 1s;
                }
            }
        }

        &.hide {
            max-width: 2.5%;
            flex: 0 0 0;
            padding-left: 0;
            padding-right: 0;
            transition: all 500ms;
            transition-delay: 200ms;

            .content {
                overflow: hidden;
                opacity: 0;
                transition-duration: 200ms;
            }

            .content {
                visibility: hidden;
                transition-delay: 200ms;
            }

            .btn-collapse {
                // Above content scroll bar
                z-index: 2000;

                svg.fa-angle-right {
                    // I'm sorry, I had to add important here
                    transform-origin: initial !important;
                    transform: scaleX(-1);
                    transition-duration: 500ms;
                }
            }
        }
    }
}

.dashboard-sidebar {
    padding-left: 0;

    .sidebar-content {
        overflow: hidden;

        .tile-list {
            padding-top: 5px;
            padding-bottom: 15px;

            .col-12 {
                padding-left: 8px;
                padding-right: 8px;

                .tile-item {
                    font-family: "Source Sans Pro", sans-serif;
                    background-color: $streambank-grey50;
                    border: 1px solid $streambank-grey30;
                    height: 65px;
                    margin-top: 5px;
                    margin-bottom: 5px;
                    padding-left: 10px;
                    padding-right: 10px;

                    .fa-layers {
                        width: 0;
                        height: 0;
                        top: 7px;

                        >svg {
                            color: $streambank-blue40;
                        }
                        .fa-circle {
                            color: $streambank-white;
                        }
                    }

                    .tile-title {
                        font-size: 12px;
                        color: $streambank-blue80;
                    }

                    .tile-subtitle {
                        font-size: 10px;
                    }

                    &.primary {
                        background-color: $streambank-primary;
                        color: $streambank-white;

                        .tile-title {
                            color: $streambank-white;
                        }
                    }

                    &.success {
                        background-color: $streambank-complete;
                        color: $streambank-white;

                        .tile-title {
                            color: $streambank-white;
                        }
                    }
                }
            }
        }
    }
}

.backdrop-sidebar {
    width: 100%;
    height: calc(100% - 40px);
    position: absolute;
    top: 40px;
    margin-right: 0;
    margin-left: 0;
    overflow: hidden;

    .backdrop-sidebar-container {
        overflow-y: auto;
    }

    &.show {
        opacity: 1;

        .backdrop-layer {
            opacity: 0.5;
            transition-duration: 500ms;
        }

        .backdrop-sidebar-container {
            right: 0;
            transition-duration: 500ms;
        }
    }

    &.hide {
        opacity: 0;
        z-index: -1;
        transition-duration: 500ms;

        .backdrop-layer {
            opacity: 0;
            z-index: -1;
            transition-duration: 500ms;
        }

        .backdrop-sidebar-container {
            right: -240px;
            z-index: -1;
            transition-duration: 500ms;
        }
    }

    .col-9, .col-3, .col-12 {
        height: 100%;
        padding-right: 0;
        padding-left: 0;
    }

    .col-12 {
        position: fixed;
        z-index: 50;
    }

    .col-3 {
        z-index: 51;
    }

    hr {
        margin-top: 10px;
    }

    .backdrop-layer {
        width: 100%;
        height: 100%;
        background-color: $streambank-blue80;
    }

    .backdrop-sidebar-container {
        display: flex;
        flex-direction: column;
        position: relative;
        height: 100%;
        font-size: 12px;
        //margin-left: 50px;
        padding: 16px;
    }
}

.account-menu, .quick-nav {
    z-index: 2010; // has to be greater than return-bar's 1030
}

.account-menu {

    .backdrop-sidebar-container {
        background-color: $streambank-white;
        justify-content: space-between;

        .page-title-component {
            margin-top: 10px;

            .page-title {
                font-size: 14px;
            }
        }

        .account-menu-title {
            display: flex;
            align-items: center;

            svg {
                font-size: 16px;
                color: $streambank-grey20;
                padding-right: 6px;

                &.success {
                    color: $streambank-green20;
                }

                &.warning {
                    color: darken($streambank-warning, 25);
                }
            }
        }

        .menu-link {
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            color: $streambank-blue40;
            margin-bottom: 15px;

            svg {
                font-size: 14px;
            }

            .fa-layers.danger {
                position: relative;
                top: -1px;
                margin-right: 5px;

                svg.fa-circle  {
                    color: $streambank-danger;
                }

                .fa-layers-text {
                    color: $streambank-white;
                    font-size: 10px;
                }
            }
        }

        .menu-footer {
            display: flex;
            flex-direction: column;
            align-items: center;

            .current-user {
                font-size: 10px;
                font-weight: 400;
                margin-bottom: 10px;
            }

            .copyright {
                font-size: 10px;
                color: $streambank-grey10;
            }

            .btn {
                width: 100%;
            }
            
            img.logo {
                width: 50%;
                margin-bottom: 10px;
            }
        }

        .btn {
            font-size: 12px;

            &.btn-menu {
                background-color: $streambank-blue50;
                border-color: $streambank-blue50;
                color: $streambank-white;
                width: 100%;
                margin-bottom: 6px;

                &:focus, &:active, &.active, &:hover, &:active:hover {
                    background-color: darken($streambank-blue50, 10);
                }

                &[disabled] {
                    border-color: $streambank-grey30;
                    background-color: $streambank-grey30;
                    color: $streambank-grey10;
                }
            }
        }
    }
}

.quick-nav {
    .backdrop-sidebar-container {
        color: $streambank-white;
        background-color: $streambank-blue70;

        a {
            color: $streambank-white;

            .pill-link {
                padding: 4px 16px;
                border-radius: 12px;
                background-color: $streambank-blue40;
                margin-bottom: 12px;
                text-align: left;

                &.has-progress {
                    margin-bottom: 16px;
                }

                & > span {
                    width: min-content;
                }

                &.child {
                    margin-left: 30px;
                    border-color: $streambank-blue80;
                    background-color: $streambank-blue80;

                    &:focus, &:active, &.active, &:hover, &:active:hover {
                        background-color: darken($streambank-blue80, 10);
                    }
                }

                &.complete {
                    border-color: $streambank-complete;
                    background-color: $streambank-complete;

                    &:focus, &:active, &.active, &:hover, &:active:hover {
                        background-color: darken($streambank-complete, 10);
                    }
                }

                &.rust {
                    border-color: $streambank-brown40;
                    background-color: $streambank-brown40;

                    &:focus, &:active, &.active, &:hover, &:active:hover {
                        background-color: darken($streambank-brown40, 10);
                    }
                }

                &.current {
                    border-color: $streambank-white;
                    background-color: $streambank-white;
                    color: $streambank-blue70;

                    &:focus, &:active, &.active, &:hover, &:active:hover {
                        background-color: darken($streambank-white, 10);
                    }

                    &::before {
                        content: "";
                        border-style: solid;
                        border-width: 6px 6px 6px 0;
                        border-color: transparent #6cc7be transparent transparent;
                        position: absolute;
                        transform: rotate(180deg);
                        margin-top: 2px;
                        left: 0;
                    }

                    &.child::before {
                        left: 32px;
                    }
                }

                svg {
                    margin-right: 4px;
                }
            }

            .pill-progress {
                position: relative;
                top: 8px;
                height: 0;

                .progress {
                    background-color: $streambank-blue80;
                    height: 4px;

                    .progress-bar {
                        background-color: $streambank-green20;
                    }
                }
            }
        }
    }
}
