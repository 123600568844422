.project-details-page {
    h2 {
        font-size: 14px;
        padding-top: 10px;
    }

    #project-filter.active {
        background-color: $streambank-brown40;
    }

    .dropdown-item {
        & > svg {
            margin-right: 6px;
        }

        &.active {
            background-color: $streambank-white;
            color: $streambank-brown40;
        }
    }

    .dropdown-menu {
        max-height: 78vh;
        overflow-y: scroll;
    }

    .tile-list {
        & > .col-3 {
            padding-left: 8px;
            margin-top: 10px;
        }

        .tile-item {
            height: 160px;
            color: $streambank-blue80;
            cursor: auto;

            &.primary, &.success {
                color: $streambank-white;
            }

            .tile-title {
                text-align: center;
            
                div:last-child {
                    font-size: 14px;
                }
            }
            .tile-progress {
                .progress {
                    margin-bottom: 4px;
                }
            }
        }

        .tile-details {
            font-size: 13px;
            height: 160px;
            margin: 6px 8px 0 -8px;
            padding: 15px 20px;
            align-items: flex-start;
            z-index: 1;

            .event-details {
                width: 100%;

                .col-3 > .row-cols-12 >.col-6 {
                    padding: 0;
                }

                .col-6:last-child {
                    border-left: 1px solid $streambank-grey20;

                    .sample-event-staff {
                        white-space: normal;
                        max-height: 12ex;
                    }
                    
                    .sample-event-notes {
                        white-space: normal;
                        max-height: 12ex;
                    }
                }
            }
        }
    }
}
