.survey-page {
    .streambank-page {
        padding-left: 0;
        padding-right: 0;
    }

    .streambank-header, .streambank-body, .page-footer {
        padding-left: 15px;
        padding-right: 15px;
    }

    .survey-field {
        .survey-question {
            &.required::after {
                content: "*";
                color: $streambank-danger;
                padding-left: 5px;
                font-weight: 700;
            }
        }

        .survey-answer {
            background-color: $streambank-white;
            padding: 10px;
            border-radius: 8px;

            .form-group {
                margin-top: 5px;
                margin-bottom: 0;
            }
        }
    }

    .secondary-field {
        background-color: $streambank-grey40;
        margin: -10px;
        margin-top: 10px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        padding: 10px;
    }

    .survey-species-input input {
        background-color: $streambank-white;

        &[disabled] {
            background-color: $streambank-grey40;
        }
    }

    .photo-point-row {
        padding-top: 0px;

        .photo-point-content {
            .photo {
                margin-bottom: 10px;

                .fa-layers {
                    right: 0;
                    left: -5px;
                }

                .no-image {
                    .fa-camera {
                        font-size: 36px;
                        margin-bottom: 14px;
                    }

                    .btn-action {
                        padding: 4px 14px;
                        white-space: nowrap;
                    }
                }

                &::after {
                    padding-bottom: 100%;
                }
            }
        }
    }
}
